/* This example requires Tailwind CSS v2.0+ */
import { useEffect, Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
  CalendarIcon,
  ChartBarIcon,
  FolderIcon,
  HomeIcon,
  InboxIcon,
  MenuIcon,
  UsersIcon,
  XIcon,
  LogoutIcon,
  InformationCircleIcon,
} from '@heroicons/react/outline'
import { useAuth } from '../hooks/useAuth'
import { useRouter } from 'next/router'
import Image from 'next/image'
import { useIntercom } from 'react-use-intercom'

const navigation = [
  { name: 'Dashboard', href: '#', icon: HomeIcon, current: true },
  {
    name: 'F.A.Q',
    href: 'https://help.jtsstrength.com/en/collections/2662663-juggernaut-ai-app',
    icon: InformationCircleIcon,
    current: false,
    external: true,
  },
  {
    name: 'JTSStrength.com',
    href: 'https://www.jtsstrength.com',
    icon: FolderIcon,
    current: false,
    external: true,
  },
  { name: 'Sign Out', href: '#', icon: LogoutIcon, current: false },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function AccountLayout({ children }) {
  const [sidebarOpen, setSidebarOpen] = useState(false)

  const auth = useAuth()
  const router = useRouter()
  const { boot, shutdown, hide, show, update, trackEvent } = useIntercom()

  const handleOnPress = (item) => {
    if (item.name === 'Sign Out') {
      auth.signOut()
      return router.push('/')
    }
    if (item.external) {
      return window.open(item.href)
    }
    if (item.href !== '#') {
      return router.push(item.href)
    }
  }

  useEffect(() => {
    if (auth.user && !auth.isLoading && auth.userHash) {
      boot({ email: auth.user.email, userHash: auth.userHash })
      trackEvent('viewed-ai-web-account-page')
    }
  }, [auth.user, auth.isLoading, auth.userHash])

  return (
    <div className='h-screen flex overflow-hidden bg-gray-800'>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as='div'
          static
          className='fixed inset-0 flex z-40 md:hidden'
          open={sidebarOpen}
          onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter='transition-opacity ease-linear duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='transition-opacity ease-linear duration-300'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'>
            <Dialog.Overlay className='fixed inset-0 bg-gray-600 bg-opacity-75' />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter='transition ease-in-out duration-300 transform'
            enterFrom='-translate-x-full'
            enterTo='translate-x-0'
            leave='transition ease-in-out duration-300 transform'
            leaveFrom='translate-x-0'
            leaveTo='-translate-x-full'>
            <div className='relative flex-1 flex flex-col max-w-xs w-full bg-gray-800'>
              <Transition.Child
                as={Fragment}
                enter='ease-in-out duration-300'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='ease-in-out duration-300'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'>
                <div className='absolute top-0 right-0 -mr-12 pt-2'>
                  <button
                    className='ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
                    onClick={() => setSidebarOpen(false)}>
                    <span className='sr-only'>Close sidebar</span>
                    <XIcon className='h-6 w-6 text-white' aria-hidden='true' />
                  </button>
                </div>
              </Transition.Child>
              <div className='flex-1 h-0 pt-5 pb-4 overflow-y-auto'>
                <div className='flex-shrink-0 flex items-center px-10'>
                  <Image
                    className=' block'
                    src='/MainLogo-assets/juggBJJLogo.png'
                    alt='Juggernaut AI'
                    objectFit='contain'
                    width={350}
                    height={150}
                  />
                </div>
                <nav className='mt-5 px-2 space-y-1'>
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className={classNames(
                        item.current
                          ? 'bg-black text-white'
                          : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                        'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                      )}>
                      <item.icon
                        className={classNames(
                          item.current
                            ? 'text-gray-300'
                            : 'text-gray-400 group-hover:text-gray-300',
                          'mr-4 h-6 w-6'
                        )}
                        aria-hidden='true'
                      />
                      {item.name}
                    </a>
                  ))}
                </nav>
              </div>
            </div>
          </Transition.Child>
          <div className='flex-shrink-0 w-14'>
            {/* Force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className='hidden md:flex md:flex-shrink-0'>
        <div className='flex flex-col w-64'>
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className='flex flex-col h-0 flex-1 bg-gray-800'>
            <div className='flex-1 flex flex-col pt-5 pb-4 overflow-y-auto'>
              <div className='flex items-center flex-shrink-0 px-2'>
                <Image
                  className=' block'
                  src='/MainLogo-assets/juggBJJLogo.png'
                  alt='Juggernaut AI'
                  objectFit='contain'
                  width={350}
                  height={150}
                />
              </div>
              <nav className='mt-5 flex-1 px-2 bg-gray-800 space-y-1'>
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    className={classNames(
                      item.current
                        ? 'bg-black text-white'
                        : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                      'group flex items-center px-2 py-2 text-sm font-medium rounded-md cursor-pointer'
                    )}
                    onClick={() => handleOnPress(item)}>
                    <item.icon
                      className={classNames(
                        item.current
                          ? 'text-gray-300'
                          : 'text-gray-400 group-hover:text-gray-300',
                        'mr-3 h-6 w-6'
                      )}
                      aria-hidden='true'
                    />
                    {item.name}
                  </a>
                ))}
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-col w-0 flex-1 overflow-hidden'>
        <div className='md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3'>
          <button
            className='-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-400 hover:text-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500'
            onClick={() => setSidebarOpen(true)}>
            <span className='sr-only'>Open sidebar</span>
            <MenuIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>
        <main className='flex-1 relative z-0 overflow-y-auto focus:outline-none bg-gray-900'>
          {/* <div className="max-w-10xl mx-auto px-4 sm:px-6 lg:px-8">
               <h1 className="text-2xl font-semibold text-gray-100">{router.pathname}</h1>
             </div> */}
          <div className='max-w-full mx-auto'>{children}</div>
        </main>
      </div>
    </div>
  )
}
