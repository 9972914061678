import { createContext, useContext, useState } from 'react';

const notifications = {
  message: '',
  setMessage: (message) => {},
  showNotification: false,
  setShowNotification: (notification) => {},
  notificationType: 'basic',
  setNotificationType: (type) => {},
  title: '',
  setTitle: (title) => {},
  isModal: false,
  setIsModal: (isModal) => {},
}
const notificationContext = createContext(notifications)

export const useNotifications = () => useContext(notificationContext)

export const NotificationProvider = ({ children }) => {
  const notificationsContent = useNotificationContent()
  return (
    <notificationContext.Provider value={notificationsContent}>
      {children}
    </notificationContext.Provider>
  )
}

const useNotificationContent = () => {
  const [title, setTitle] = useState('')
  const [message, setMessage] = useState('')
  const [showNotification, setShowNotification] = useState(false)
  const [notificationType, setNotificationType] = useState('basic')
  const [isModal, setIsModal] = useState(false)

  return {
    title,
    setTitle,
    message,
    setMessage,
    showNotification,
    setShowNotification,
    notificationType,
    setNotificationType,
    isModal,
    setIsModal,
  }
}
