import { createContext, useContext, useState } from 'react'

const loadingInitial = {
  message: '',
  setMessage: (message) => {},
  showLoading: false,
  setShowLoading: (set) => {},
  setLoading: (status = false, message = '') => {},
}
const loadingContext = createContext(loadingInitial)

export const useLoading = () => useContext(loadingContext)

export const LoadingProvider = ({ children }) => {
  const loadingContent = useNotificationContent()
  return (
    <loadingContext.Provider value={loadingContent}>
      {children}
    </loadingContext.Provider>
  )
}

const useNotificationContent = () => {
  const [message, setMessage] = useState('Loading...')
  const [showLoading, setShowLoading] = useState(false)

  const setLoading = (status = false, message = '') => {
    setMessage(message)
    setShowLoading(status)
  }

  return {
    showLoading,
    setShowLoading,
    message,
    setMessage,
    setLoading,
  }
}
