import Head from 'next/head'
import { AppProps } from 'next/app'
import { ProvideAuth } from '../src/hooks/useAuth'
import Layout from '../src/components/Layout'
import '../styles/globals.css'
import 'tailwindcss/tailwind.css'
import { useRouter } from 'next/router'
import AccountLayout from '@/components/components/AccountLayout'
import { IntercomProvider, useIntercom } from 'react-use-intercom'
import { NotificationProvider } from '@/components/hooks/useNotifications'
import Notification from '@/components/components/notifications/Notification'
import { LoadingScreen } from '@/components/components/notifications/Loading'
import { LoadingProvider } from '@/components/hooks/useLoading'
import FacebookPixel from '@/components/components/FacebookPixel'

const INTERCOM_APP_ID = process.env.INTERCOM_APP_ID
function MyApp({ Component, pageProps }: AppProps) {
  const route = useRouter()

  return (
    <ProvideAuth>
      <FacebookPixel>
        <IntercomProvider appId={INTERCOM_APP_ID}>
          <NotificationProvider>
            <Head>
              <title>
                JuggernautBJJ | Taking your Brazilian Jiu-Jitsu training to
                another level
              </title>
              <meta name='apple-mobile-web-app-capable' content='yes' />
              <meta
                name='viewport'
                content='width=device-width, initial-scale=1.0, user-scalable=no'
              />
              <meta
                name='facebook-domain-verification'
                content='old2ifpk4nmv92n9qlh2m6hw59yw8k'
              />
              <link rel='icon' href='/favicon.ico' />
              <link
                rel='stylesheet'
                type='text/css'
                href='https://cloud.typography.com/7239154/6007032/css/fonts.css'
              />
            </Head>
            <LoadingProvider>
              {route.pathname.includes('account') ? (
                <AccountLayout>
                  <Component {...pageProps} />
                </AccountLayout>
              ) : (
                <Layout>
                  <Component {...pageProps} />
                </Layout>
              )}
              <LoadingScreen />
              <Notification />
            </LoadingProvider>
          </NotificationProvider>
        </IntercomProvider>
      </FacebookPixel>
    </ProvideAuth>
  )
}

export default MyApp
