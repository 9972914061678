import { useRouter } from 'next/router'

import { Transition } from '@headlessui/react'
import { useState } from 'react'
import Image from 'next/image'
import Link from 'next/link'
const Nav = () => {
  const router = useRouter()
  return (
    <nav className='bg-gray-900 shadow fixed w-full z-30'>
      <div className='max-w-7xl mx-auto px-4 py-3 sm:px-6 lg:px-8'>
        <div className='flex justify-between h-20'>
          <div className='flex'>
            {/* <div className="-ml-2 mr-2 flex items-center md:hidden">
          <button onClick={() => setIsOpen(!isOpen)} type="button" className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-400 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500" aria-controls="mobile-menu" aria-expanded="false">
          
            <span className="sr-only">Open main menu</span>
           
            <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
           
            <svg className="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div> */}

            <div className='flex flex-1 items-center pr-5 w-auto text-white'>
              {/* <img className="block lg:hidden h-8 w-auto" src="/JuggernautAI-White.png" alt="JuggernautAI" />
          <img className="hidden lg:block h-8 w-auto" src="/JuggernautAI-White.png" alt="JuggernautAI" /> */}

              <Image
                className=' block'
                src='/MainLogo-assets/juggBJJLogo.png'
                alt='Juggernaut AI'
                objectFit='contain'
                width={250}
                height={150}
              />
            </div>

            {/* <div className="hidden md:ml-6 md:flex md:space-x-8">
          <a href="/about#howitworks" className="border-transparent text-gray-800 hover:border-gray-300 hover:text-white inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium">
            How It Works
          </a>
           <a href="/about" className="border-transparent text-gray-800 hover:border-gray-300 hover:text-white inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium">
            About
          </a>
          <a href="/contact" className="border-transparent text-gray-800 hover:border-gray-300 hover:text-white inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium">
            Contact
          </a>
          <a href="https://www.jtsstrength.com" target="_blank" className="border-transparent text-gray-800 hover:border-gray-300 hover:text-white inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium">
            JTSStrength.com
          </a>
        </div> */}
          </div>

          <div className='flex items-center w-auto flex-shrink-0'>
            <Link
              href={{
                pathname: '/register',
                query: router.query.via ? { via: router.query.via } : {},
              }}>
              <button
                type='button'
                className='relative inline-flex items-center px-4 py-2 border border-transparent text-xs md:text-sm font-medium rounded-md text-white bg-primary-500 shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 ml-1'>
                <span className='text-'>
                  {' '}
                  <a> Register</a>
                </span>
              </button>
            </Link>
            <Link
              href={{
                pathname: '/login',
                query: router.query.via ? { via: router.query.via } : {},
              }}>
              <button
                type='button'
                className='relative inline-flex items-center px-2 md:px-4 py-2 border border-transparent text-xs md:text-sm font-medium rounded-md text-primary-500 bg-grey-100 shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 hover:text-white border-primary-500 ml-1 md:ml-5'>
                <span className='text-'>
                  {' '}
                  <a> Log In</a>
                </span>
              </button>
            </Link>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Nav
