import { useEffect } from 'react'
import Footer from './Footer'
import 'tailwindcss/tailwind.css'
import { useAuth } from '../hooks/useAuth'
import { useRouter } from 'next/router'
import { useIntercom } from 'react-use-intercom'
import Nav from './MainNav'

const loginPages = ['/account', '/login', '/register', '/forgot']

const Layout = ({ children }) => {
  const { user } = useAuth()

  const route = useRouter()

  const { boot } = useIntercom()

  useEffect(() => {
    boot()
  }, [])
  return (
    <div className='body not-italic font-normal'>
      {!loginPages.includes(route.pathname) ? (
        <>
          <Nav />
          <div className='bg-gray-800 lg:overflow-hidden h-full min-h-screen pt-24'>
            {children}
          </div>
        </>
      ) : (
        <div>{children}</div>
      )}

      <footer className='bg-gray-800'>
        <div className='max-w-7xl mx-auto py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8'>
          <div className='mt-8 md:mt-0 md:order-1'>
            <p className='text-center text-base text-white'>
              &copy; {new Date().getFullYear()} Juggernaut Training Systems LLC.
              All rights reserved.
            </p>
          </div>
        </div>
      </footer>
      {/* <Footer /> */}
    </div>
  )
}

export default Layout
