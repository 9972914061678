import { useState, useEffect, useContext, createContext } from 'react'
import { firestore } from '../firebaseClient'
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  setPersistence,
  sendEmailVerification,
  browserSessionPersistence,
  signOut,
  sendPasswordResetEmail,
  confirmPasswordReset,
  onAuthStateChanged,
  User,
} from 'firebase/auth'
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from '@firebase/firestore'
const authContext = createContext({
  user: null,
  signIn: async (_email: any, _password: any): Promise<any> => {
    return
  },
  signOut: async (): Promise<any> => {
    return
  },
  signUp: async ({}): Promise<any> => {
    return
  },
  confirmPasswordReset: async (_code: any, _password: any): Promise<any> => {
    return
  },
  sendPasswordResetEmail: async (_email: any): Promise<any> => {
    return
  },
  isLoading: true,
  userHash: null,
  userSubscriptions: null,
  hasTrialed: false,
})
// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth()
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}
// Hook for child components to get the auth object ...
// ... and re-render when it changes.

export const useAuth = () => useContext(authContext)
// Provider hook that creates auth object and handles state

function useProvideAuth() {
  const [user, setUser] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [userHash, setUserHash] = useState('')
  const [userSubscriptions, setUserSubscriptions] = useState(null)
  const [hasTrialed, setHasTrailed] = useState(false)
  const auth = getAuth()

  // Wrap any Firebase methods we want to use making sure ...
  // ... to save the user to state.
  const signIn = async (email: string, password: string) => {
    try {
      await setPersistence(auth, browserSessionPersistence)

      const user = await signInWithEmailAndPassword(auth, email, password)
      setUser(user.user)
      return user
    } catch (error) {
      let errorMessage: string
      if (error.code === 'auth/user-not-found') {
        errorMessage =
          'Account not found. If you were are a previous jtsstrength.com spreadsheet user, you will need to create a new account and program. Please go back to the previous screen and select Create Program.'
      } else if (error.code === 'auth/email-already-in-use') {
        errorMessage =
          'Email address is already in use \n Please go back and log in to your account'
      } else if (error.code === 'auth/invalid-email') {
        errorMessage = 'Invalid email'
      } else if (error.code === 'auth/weak-password') {
        errorMessage = 'Please enter a stronger password'
      } else if (
        error.code === 'auth/unknown' ||
        error.code === 'auth/network-request-failed'
      ) {
        errorMessage =
          'Unable to log in. Please check your connection and try again.'
      } else if (error.code === 'auth/wrong-password') {
        errorMessage =
          'Unable to log in. Please check your email and/or password'
      } else {
        errorMessage = error.message
      }

      throw new Error(errorMessage)
    }
  }
  const signUp = async ({ email, password, firstName, lastName }) => {
    try {
      await setPersistence(auth, browserSessionPersistence)

      const newUser = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      )

      await sendEmailVerification(newUser.user)
      await setDoc(
        doc(firestore, `users/${newUser.user.uid}`),
        {
          name: `${firstName} ${lastName}`,
          role: 'user',
          passedSignUp: false,
          // lastSeen: new Date()
        },
        { merge: true }
      )

      setUser(newUser.user)
      return newUser.user
    } catch (error) {
      let errorMessage: string

      if (error.code === 'auth/user-not-found') {
        errorMessage =
          'Account not found. If you were are a previous jtsstrength.com spreadsheet user, you will need to create a new account and program. Please go back to the previous screen and select Create Program.'
      } else if (error.code === 'auth/email-already-in-use') {
        errorMessage =
          'Email address is already in use \n Please go back and log in to your account'
      } else if (error.code === 'auth/invalid-email') {
        errorMessage = 'Invalid email'
      } else if (error.code === 'auth/weak-password') {
        errorMessage = 'Please enter a stronger password'
      } else if (
        error.code === 'auth/unknown' ||
        error.code === 'auth/network-request-failed'
      ) {
        errorMessage =
          'Unable to log in. Please check your connection and try again.'
      } else if (error.code === 'auth/wrong-password') {
        errorMessage =
          'Unable to log in. Please check your email and/or password'
      } else {
        errorMessage = error.message
      }
      throw new Error(errorMessage)
    }
  }
  const authSignOut = async () => {
    await signOut(auth)
    setUser(false)
  }
  const authSendPasswordResetEmail = async (email: string) => {
    await sendPasswordResetEmail(auth, email)
    return true
  }
  const authConfirmPasswordReset = async (code: string, password: string) => {
    await confirmPasswordReset(auth, code, password)
    return true
  }

  const getUserSubs = async (uid: string) => {
    const subsRef = collection(
      firestore,
      `users/${uid || user.uid}/subscriptions`
    )
    const userSub = await getDocs(
      query(subsRef, where('status', 'in', ['trialing', 'active', 'past_due']))
    )

    const trialSubs = await getDocs(
      query(subsRef, where('trial_start', '!=', null))
    )

    const subs = []
    if (!userSub.empty) {
      userSub.forEach((doc) => subs.push(doc.data()))
      setUserSubscriptions(subs)
    }
    if (!trialSubs.empty) {
      setHasTrailed(true)
    }
    return
  }
  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUser(user)
        const crypt = require('crypto')
          .createHmac('sha256', process.env.INTERCOM_SECRET_KEY)
          .update(user.email)
          .digest('hex')
        setUserHash(crypt)
        await getUserSubs(user.uid)

        setIsLoading(false)
      } else {
        setUserHash('')
        setIsLoading(false)
        setUser(null)
        setHasTrailed(false)
        setUserSubscriptions(null)
      }
    })
    // Cleanup subscription on unmount
    return () => unsubscribe()
  }, [])
  // Return the user object and auth methods
  return {
    user,
    signIn,
    signUp,
    signOut: authSignOut,
    isLoading,
    sendPasswordResetEmail: authSendPasswordResetEmail,
    confirmPasswordReset: authConfirmPasswordReset,
    userHash,
    userSubscriptions,
    getUserSubs,
    hasTrialed,
  }
}
