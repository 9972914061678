import {initializeApp, getApp, getApps} from 'firebase/app'
import {getFirestore} from 'firebase/firestore'
import { getFunctions } from '@firebase/functions'

const CLIENT_CONFIG = {
  apiKey: process.env.apiKey,
  authDomain: process.env.authDomain,
  databaseURL: process.env.databaseURL,
  projectId: process.env.projectId,
  storageBucket: process.env.storageBucket,
  messagingSenderId: process.env.messagingSenderId,
  appId: process.env.appId,
}

const firebaseApp = !getApps().length ? initializeApp(CLIENT_CONFIG) : getApp()
const firestore = getFirestore(firebaseApp)
const functions = getFunctions(firebaseApp)
if (typeof window !== 'undefined' ) {
  ;(window as any).firebase = firebaseApp
}

export { firebaseApp as firebaseClient, firestore, functions }
